import store from "../store";
import { getCurrentTextTranslation } from "./getCurrentTextTranslation";

export const getLimitedCriteriaAnswers = () => {
  const question = store.getState().questions.question;

  return [
    {
      paramName: "",
      scale: undefined,
      criterias:
        question?.answerTemplate?.criteria?.map((criteria) => ({
          name: getCurrentTextTranslation(criteria?.textTranslations),
          score: undefined,
        })) || [],
    },
  ];
};

export const getNumberOfOptions = () => {
  const currentQuestion = store.getState().questions.question;
  const numberOfOptions =
    currentQuestion?.answerTemplate?.numberOfAssessments || currentQuestion?.answerTemplate?.criteria?.length || 10;

  return Array.from({ length: numberOfOptions }, (_, index) => ({
    label: String(index + 1),
    value: index + 1,
  }));
};
