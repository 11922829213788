import { CsiAnswerCriteria } from "./csiAnswerCriteria";

export interface CsiAnswer {
  criterias: Array<CsiAnswerCriteria>;
  paramName: string;
  scale?: ScaleEnum;
}

export enum ScaleEnum {
  BOOLEAN = "BOOLEAN",
  FIVE_POINTS = "FIVE_POINTS",
  TEN_POINTS = "TEN_POINTS",
}
