import "../index.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { criteriaOptions } from "../../../helpers/criteriaOptions";
import { criteriaAnswers } from "../../../helpers/criteriaAnswers";
import { CsiAnswer } from "../../../types/csiAnswer";
import { useActions } from "../../../hooks/useActions";
import MarkdownParser from "../../../components/markdown";

const CsiComponent: FC = () => {
  const { question, appLang, answer } = useSelector((state: RootState) => {
    return {
      question: state.questions.question,
      answer: state.questions.answer,
      appLang: state.utils.lang,
    };
  });
  const [options, setOptions] = useState(criteriaOptions());
  const [answers, setAnswers] = useState<Array<CsiAnswer>>(criteriaAnswers());
  const { setUserAnswer } = useActions();

  useEffect(() => {
    setOptions(criteriaOptions());
    setAnswers(criteriaAnswers());
    setUserAnswer({
      answerValue: undefined,
      csiAnswers: criteriaAnswers(),
    });
  }, [appLang, question?.questionId]);

  const onSelect = useCallback(
    (score: number, paramName: string, criteriaName: string) => {
      const csiAnswers = answers.map((answer$) =>
        answer$.paramName !== paramName
          ? answer$
          : {
              ...answer$,
              criterias: answer$.criterias.map((criteria$) =>
                criteria$.name !== criteriaName ? criteria$ : { ...criteria$, score },
              ),
            },
      );
      setAnswers(csiAnswers);
      setUserAnswer({ ...answer, csiAnswers });
    },
    [answers, setAnswers, setUserAnswer],
  );

  return (
    <div className={"csi-template"}>
      {answers.map((csi, i) => {
        return (
          <div key={i} className={"csi-item"}>
            <h2
              className={question?.surveyDecor?.textFont ? "disable-global-font" : ""}
              style={{ fontFamily: question?.surveyDecor?.textFont }}
            >
              <MarkdownParser mixedString={csi.paramName} />
            </h2>
            <div className={"criteria-container"}>
              {csi.criterias.map((criteria, j) => {
                return (
                  <div className={"criteria-item"} key={j} id={String(i + j)}>
                    <h3
                      className={`criteria-item ${question?.surveyDecor?.textFont ? "disable-global-font" : ""}`}
                      style={{ fontFamily: question?.surveyDecor?.textFont }}
                    >
                      <MarkdownParser mixedString={criteria.name} />
                    </h3>
                    <Select
                      onChange={(value) => onSelect(value, csi.paramName, criteria.name)}
                      className={"criteria-select"}
                      value={criteria.score}
                      variant={"borderless"}
                      placeholder={". . ."}
                      options={options}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CsiComponent;
