import { FC, useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { criteriaAnswers } from "../../../helpers/criteriaAnswers";
import { CsiAnswer } from "../../../types/csiAnswer";
import { useActions } from "../../../hooks/useActions";
import { getLimitedCriteriaAnswers, getNumberOfOptions } from "../../../helpers/getLimitedCriteriaAnswers";
import MarkdownParser from "../../../components/markdown";

const LimitedRangingComponent: FC = () => {
  const { question, appLang, answer } = useSelector((state: RootState) => ({
    question: state.questions.question,
    answer: state.questions.answer,
    appLang: state.utils.lang,
  }));

  const [options, setOptions] = useState(getNumberOfOptions());
  const [answers, setAnswers] = useState<Array<CsiAnswer>>(getLimitedCriteriaAnswers());
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const { setUserAnswer } = useActions();

  useEffect(() => {
    setOptions(getNumberOfOptions());
    setAnswers(getLimitedCriteriaAnswers());
    setSelectedValues([]);
    setUserAnswer({
      answerValue: undefined,
      csiAnswers: criteriaAnswers(),
    });
  }, [appLang, question?.questionId]);

  const getAvailableOptions = useCallback(
    (currentValue?: number) => {
      return options.filter((option) => !selectedValues.includes(option.value) || option.value === currentValue);
    },
    [options, selectedValues],
  );

  const onSelect = useCallback(
    (score: number, paramName: string, criteriaName: string, oldValue?: number) => {
      setSelectedValues((prev) => {
        const newValues = prev.filter((v) => v !== oldValue);
        if (score !== undefined) {
          newValues.push(score);
        }
        return newValues;
      });

      const csiAnswers = answers.map((answer$) =>
        answer$.paramName !== paramName
          ? answer$
          : {
              ...answer$,
              criterias: answer$.criterias.map((criteria$) =>
                criteria$.name !== criteriaName ? criteria$ : { ...criteria$, score },
              ),
            },
      );
      setAnswers(csiAnswers);
      setUserAnswer({ ...answer, csiAnswers });
    },
    [answers, answer, setUserAnswer],
  );

  return (
    <div className="csi-template">
      {answers.map((csi, i) => (
        <div key={i} className="csi-item">
          <h2
            className={question?.surveyDecor?.textFont ? "disable-global-font" : ""}
            style={{ fontFamily: question?.surveyDecor?.textFont }}
          >
            {csi.paramName}
          </h2>
          <div className="criteria-container">
            {csi.criterias.map((criteria, j) => (
              <div className="criteria-item" key={j} id={String(i + j)}>
                <h3
                  className={`criteria-item ${question?.surveyDecor?.textFont ? "disable-global-font" : ""}`}
                  style={{ fontFamily: question?.surveyDecor?.textFont }}
                >
                  <MarkdownParser mixedString={criteria.name} />
                </h3>
                <Select
                  onChange={(value) => onSelect(value, csi.paramName, criteria.name, criteria.score)}
                  className="criteria-select"
                  value={criteria.score}
                  variant="borderless"
                  placeholder=". . ."
                  notFoundContent="----"
                  options={getAvailableOptions(criteria.score)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LimitedRangingComponent;
