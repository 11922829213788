import { AnswerChoice } from "./answerChoice";
import { CsiParam } from "./csiParam";
import { NpsInfoParams } from "./npsInfoParams";
import { TextTranslationType } from "./textTranslation";
import { CsiCriteria } from "./csiCriteria";

export interface AnswerTemplate {
  readonly addCustomAnswer?: boolean;
  readonly answerChoices?: Array<AnswerChoice>;
  readonly csiParams?: Array<CsiParam>;
  readonly criteria?: Array<CsiCriteria>;
  readonly csiScale?: CsiScaleEnum;
  readonly customAnswerTextEn?: string;
  readonly customAnswerTextKk?: string;
  readonly customAnswerTextRu?: string;
  readonly type?: TypeEnum;
  readonly npsInfoParams?: NpsInfoParams;
  readonly customAnswerIsRequired?: boolean;
  readonly filesUpload?: Array<FileUpload>;
  readonly textTranslations?: Array<TextTranslationType>;
  readonly actionLabel?: string;
  readonly textTranslationsToHighestScoreLabel?: Array<TextTranslationType>;
  readonly textTranslationsToLowestScoreLabel?: Array<TextTranslationType>;
  readonly maxOptionLimit?: number;
  readonly hideLineOrder?: boolean;
  readonly mixedAnswers?: boolean;
  readonly customAnswerLineOption: CustomAnswerLineOptionsEnum;
  readonly customRegexp?: string;
  readonly numberOfAssessments?: number;
}

type FileUpload = {
  answerTemplateId: number;
  fileUploadId: number;
  filepath: string;
};

export enum TypeEnum {
  CSI = "CSI",
  LIMITED_RANGING = "LIMITED_RANGING",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  NPS = "NPS",
  FILE_UPLOAD = "FILE_UPLOAD",
  INFO_BLOCK = "INFO_BLOCK",
  START_BLOCK = "START_BLOCK",
  END_BLOCK = "END_BLOCK",
  EMAIL = "EMAIL",
  IIN = "IIN",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
  DATE = "DATE",
  DATETIME = "DATETIME",
  LOYALTY_INDEX = "LOYALTY_INDEX",
  RATING = "RATING",
  RANGING = "RANGING",
}

export enum CsiScaleEnum {
  BOOLEAN = "BOOLEAN",
  FIVE_POINTS = "FIVE_POINTS",
  TEN_POINTS = "TEN_POINTS",
}

export enum CustomAnswerLineOptionsEnum {
  ONE_LINE = "ONE_LINE",
  MULTILINE = "MULTILINE",
}
